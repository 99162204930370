<template>
  <div>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12">
          <h1 class="mt-4" style="font-size: 25px;">J.D. POWER AUTO SUMMIT</h1>
          <h2 style="font-size: 20px;">MODERN RETAILING: KEEPING UP WITH THE PACE OF CHANGE</h2>
        </b-col>

        <b-col md="8" class="mt-4-lg">
          
          <div class="mt-4">
            <p>A modern dealership should put people first and keep customer needs paramount, and technology tools can help store leaders get there. So concluded panelists speaking at the J.D. Power Auto Summit on Jan. 26, in Dallas. The group of industry experts and insiders said dealers, automakers and vendors need to think about what the term “modern retailing” really means.</p>
            <p>“We consider modern retailing [to be] the whole process, not just what happens online,” said Brian Reed, CEO of Digital Auto Advisors and a partner with venture capital firm Automotive Ventures in Georgia. Phil Battista, president of dealership technologies and head of modern retailing at J.D. Power, suggested that the notion of creating a seamless experience for consumers isn’t quite solvable yet, in part because automakers and retailers have divergent opinions about what modern retailing is, and how and where technology is best used.
            <br><br>“What we want to do is not force consumers down a particular path,” said Battista, who moderated the panel. “We want to give consumers options.”</p>
            <p style="margin-bottom: 0px;">Read more in Automotive News</p>
            <a style="margin-top: 0px;" href="https://www.autonews.com/nada-show/jd-power-auto-summit-tech-can-help-put-people-first">https://www.autonews.com/nada-show/jd-power-auto-summit-tech-can-help-put-people-first</a>

            <br>
            <br>
            <hr>
            <br>

            <h2 style="font-size: 20px;">Insights from the Honda Digital Solutions Team</h2>
            <br>
            <p>“The modern retailing panel content resonated with us as field advisors working with dealers daily on evolving their processes. It's encouraging to hear the narratives expressed as evidence that as an industry, we are seeing the future of retailing as much more than a tool online – it’s what it’s always been about earning a customer’s trust and business by meeting them where they are in their process. The DR tool helps this but is not what makes it successful.”</p>
            <p style="font-style: italic;">– Julie Meyer and David Greenhalgh, HDS Field Advisors</p>
            <br>
            <p>“I've had the privilege of watching the evolution of the internet lead from the front seat of a dealership. I remember the day when our GM walked into our customer service department and told us we now had to help the customers on the internet because the sales team thought no one would ever buy a car online. Fast forward 20 years, and it’s almost laughable at how wrong they were. It’s not that every customer wants to buy a vehicle online, in fact, most do not. However, the consumer’s shopping preferences have changed over the past decade and dealerships need to ensure they have the proper tools and processes in place to meet the customer where they are and help them complete the process via their preferred channel. The HDS program was designed to help elevate the online to instore customer experience. Our team has the opportunity to help the dealerships rise to the challenge by investing in the people, processes, and technology that will meet the customer’s expectations and help create a seamless online to instore purchase process. It’s been amazing to watch the evolution of the online shopper over the past 20 years, and I’m excited to have the opportunity to help dealers create the seamless and transparent purchase process customers expect in this age of Modern Retailing.”</p>
            <p style="font-style: italic;">–  Stephanie Dean, HDS Team Lead</p>

            <br>
            <br>

            <p style="font-weight: 600; margin-bottom: 0px;">Want to learn more about Modern Digital Retailing through the HDS program?</p>
            <p>Contact us today at: 855-550-0085 | <a href="mailto:info@hondadigitaldealer.com">info@hondadigitaldealer.com</a> | <a href="www.in.honda.com">www.in.honda.com</a></p>
          </div>

        </b-col>

        <b-col md="4">
          <b-img
            fluid
            src="@/assets/nada_honda_team.jpg"
            style="margin-top:25px; padding: 10px;"
            class="photo"
          />
          <p style="padding: 0px 10px 0px 10px; font-style: italic; font-size: 0.8rem;">Honda Digital Solutions team members Julie Meyer, Dave Greenhalgh, Stephanie Dean and Matt Alexander pose in front of the J.D. Power NADA Show booth. </p>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

export default {
  name: 'ModernRetailing',
  components: {
    
  },

  metaInfo: {
    title: 'Modern Retailing: Keeping up the the Pace of Change'
  },

  data() {
    return {

    };
  },

  methods: {

  },

  computed: {
    indexVideo() {
      return this.$refs.indexVideo;
    },
  },
}



</script>

<style>

#faq-container {
  max-width: 600px;
}

#faq-container a {
  color: #2d2d2d;
  max-width: 600px;
}

#faq-container a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #5c88da;
}

#faq-container i:hover {
  color: rgb(23, 92, 142);
}

.faq-expanded {
  padding-top: 10px;
  padding-right: 23px;
  padding-bottom: 23px;
  padding-left: 22px;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.content {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 60px;
  }

}

</style>